/*****************************************************************************
 * UmanIT - Effet hover sur les boutons
 *****************************************************************************/

let buttonsHoverEffect = document.querySelectorAll(".btn-hovereffect");

buttonsHoverEffect.forEach(function(button) {
  const circle = button.querySelector(".btn-hovereffect__circle");

  function drawCircle(e) {

    const buttonCoord = button.getBoundingClientRect();

    // Position X et Y du curseur dans la page - la position X et Y du bouton pour n'obtenir que la position du curseur dans le bouton
    const mousePosYOnButton = e.clientY - buttonCoord.top;
    const mousePosXOnButton = e.clientX - buttonCoord.left;

    const circleWidth = buttonCoord.width * 1.5;

    circle.style.setProperty("top", `${ mousePosYOnButton }px`);
    circle.style.setProperty("left", `${ mousePosXOnButton }px`);
    circle.style.setProperty("width", `${ circleWidth }px`);
    circle.style.setProperty("height", `${ circleWidth }px`);
  }


  button.addEventListener("mouseenter", drawCircle);
  button.addEventListener("mouseleave", clearButton);

  button.addEventListener("click", activeButton);

  // sur le touch start on efface l'effet hover et on efface les transitions css qui buggent sur ios
  button.addEventListener("touchstart", function(e) {
    this.classList.add("notransition");
    this.removeEventListener("click",activeButton);
    this.removeEventListener("mouseleave", clearButton);
    this.removeEventListener("mouseenter", drawCircle);
  })

  // sur le click
  function activeButton() {

    //on ajoute une classe isHover
    this.classList.add("isHover");
    //on supprime les ecouteurs pour que le bouton reste remplis
    this.removeEventListener("mouseleave", clearButton);
    this.removeEventListener("mouseenter", drawCircle);
  }

  function clearButton() {
    circle.style.setProperty("width", "0");
    circle.style.setProperty("height", "0");
  }

});
